<template>
	<div id="confirmMeet">
		<div class="title">确认会议劳务信息</div>
		<el-row class="tabTitle">
			<el-col :span="6">
				<span>姓名</span>
			</el-col>
			<el-col :span="6">
				<span>讲者身份</span>
			</el-col>
			<el-col :span="6">
				<span>税前</span>
			</el-col>
			<el-col :span="6">
				<span>税后</span>
			</el-col>
		</el-row>
		<el-row class="tabContent" v-for="(item,i) in orderList.orders" :key="i">
			<el-col :span="6">
				<span>{{item.fullName}}</span>
			</el-col>
			<el-col :span="6">
				<span>{{item.roleName}}</span>
			</el-col>
			<el-col :span="6">
				<span class="preTax">¥{{item.preTax?item.preTax:0}}</span>
			</el-col>
			<el-col :span="6">
				<span class="afterTax">¥{{item.afterTax?item.afterTax:0}}</span>
			</el-col>
		</el-row>
		<el-row class="endNode">
			<el-col :span="6">
				<span>小计：</span>
			</el-col>
			<el-col :span="6" :offset="6">
				<span class="preAmount">¥{{orderList.preAmount?orderList.preAmount:0}}</span>
			</el-col>
			<el-col :span="6">
				<span class="amount">¥{{orderList.amount?orderList.amount:0}}</span>
			</el-col>
		</el-row>
		<div class="options">
			<el-button type="info" plain class="next" @click="sendText(false)">稍后通知</el-button>
			<el-button type="danger" class="next" @click="sendText(true)">一键通知签署协议</el-button>
		</div>
	</div>
</template>

<script>
	import {
		orderListAssistant,
		getAgreementList,
		sendText
	} from '@/request/api.js';
	export default {
		name: 'confirmMeet',
		data() {
			return {
				meetingId: '',
				orderList: '',
				agreementList: ''
			}
		},
		created() {
			this.meetingId = this.$route.query.mId;

			this.getOrderList();
		},
		methods: {
			// 一键通知签署协议
			sendText(flag) {
				if (flag) {
					let data = {
						ids: this.agreementList.map(item => item.id)
					};
					sendText(data).then(res => {
						if (res.code == 200) {
							this.$message({
								message: res.msg,
								type: 'success'
							});
							this.$notify({
								type: 'success',
								title: '发送',
								message: res.msg,
								position: 'bottom-right'
							});

							this.$router.push({
								name: 'liveUrl',
								query: {
									mId: this.meetingId,
								}
							})
							// setTimeout(() => {
							// 	uni.navigateTo({
							// 		url: `/pages/meetingDetails/details?id=${this.meetingId}`
							// 	})
							// }, 1500)
						};
					})
				} else {
					this.$router.push({
						name: 'liveUrl',
						query: {
							mId: this.meetingId
						}
					})
				}
			},

			getOrderList() {
				let data = {
					meetingId: this.meetingId
				}
				orderListAssistant(data).then(res => {
					if (res.code == 200) {
						this.orderList = res.data;
					}
				});
				getAgreementList(data).then(res => {
					if (res.code == 200) {
						this.agreementList = res.data
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	#confirmMeet {
		padding: 40px;

		.title {
			font-size: 20px;
			margin-bottom: 20px;
		}

		.el-row {
			padding: 10px 0;
			margin-bottom: 20px;
		}

		.tabTitle {
			border-bottom: 2px solid #eee;

			* {
				font-size: 16px;
				// font-weight: bold;
			}

			.el-col {
				text-align: center;
				border-right: 1px solid #ccc;

				&:last-child {
					border-right: none;
				}
			}
		}

		.tabContent {
			border-radius: 10px;
			box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);

			* {
				font-size: 15px;
			}

			.el-col {
				padding: 10px 20px;
				text-align: center;
			}

			.preTax {
				padding: 5px 20px;
				border-radius: 20px;
				background-color: #F1F6FC;
				box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
			}

			.afterTax {
				padding: 5px 20px;
				border-radius: 20px;
				background-color: #F1FCF5;
				box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
			}
		}

		.endNode {
			border-radius: 10px;
			box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);

			* {
				font-size: 16px;
				color: #0068FF;
			}

			.el-col {
				padding: 10px 20px;
				text-align: center;
			}

			.preAmount {
				font-size: 20px;
			}

			.amount {
				font-size: 20px;
			}
		}

		.options {
			margin-top: 40px;
			display: flex;
			justify-content: center;

			.el-button {
				width: 330px;
				height: 50px;
			}
		}
	}
</style>